.MtEditorContent-FileExplorer__Wrapper {
  margin-top: 15vh;
  width: 100%;
}

.MtEditorContent-DropZone__Outer-Wrapper,
.MtEditorContent-FileExplorer__Wrapper {
  display: flex;
  justify-content: center;
}

.MtEditorContent-DropZone__Inner-Wrapper,
.MtEditorContent-FileExplorer__Wrapper {
  flex: 0 0 60%;
}

/* Mobile */
@media screen AND (max-width: 425px) {
  .MtEditorContent-DropZone__Inner-Wrapper,
  .MtEditorContent-FileExplorer__Wrapper {
    flex: 0 0 90%;
  }
}

/* Tablet */
@media screen AND (min-width: 426px) AND (max-width: 768px) {
  .MtEditorContent-DropZone__Inner-Wrapper,
  .MtEditorContent-FileExplorer__Wrapper {
    flex: 0 0 80%;
  }
}
