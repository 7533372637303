/* General classes to use throughout the app */

.hidden {
  visibility: 'hidden';
}

.display-none {
  display: none;
}

.no-outline {
  outline: none !important;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-1-5 {
  padding-top: 1.5rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pr-1 {
  padding-right: 1rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.align-center {
  display: flex;
  justify-content: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}

.min-h-600px {
  min-height: 600px;
}

.Auth-Btn__Top-Bar {
  margin: 0 1rem 0 1rem;
  width: 120px;
  white-space: nowrap;
}

/* MUI customization */

/* Forcing translator's fields height to be 100% of their container's */
.MuiGrid-root .MuiTextField-root,
.MuiGrid-root .MuiInputBase-multiline {
  height: 100%;
}

/* Polaris customization */

.Polaris-Frame__TopBar {
  width: 100vw;
}

/* Removing the small space taken by the (inexistent) search field on the app's main top bar */
#Top-Bar__Main .Polaris-TopBar__SearchField {
  display: none !important;
}

/* Changing the main top bar color */
#Top-Bar__Main .Polaris-TopBar {
  background-color: #178b6e;
  padding-right: 1%;
}

/* Forcing Polaris' hamburger menu even on desktop */
.Polaris-TopBar__NavigationIcon {
  display: block !important;
  fill: #e3e5e7;
}
