.File-Explorer__Main-Wrapper {
  /* flex: 0 0 60%; */
  flex: 0 0 100%;
}

/* Mobile */
@media screen AND (max-width: 425px) {
  .File-Explorer__Main-Wrapper {
    flex: 0 0 90%;
  }
}

/* Tablet */
@media screen AND (min-width: 426px) AND (max-width: 768px) {
  .File-Explorer__Main-Wrapper {
    flex: 0 0 80%;
  }
}
